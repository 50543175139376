body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
}
.progress-animation .progress-bar.slow {
  animation: progressBar 15s ease-out forwards;
}

.progress-animation .progress-bar {
  animation: progressBar 9s ease-out forwards;
}

.progress-animation .progress-bar.fast {
  animation: progressBar 5s ease-out forwards;
}

@keyframes progressBar {
  from {width: 0%}
  to {width: 100%}
}

.loader {
  position: fixed;
  top: 55px;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 99999;
  animation: fadeAway 2s 4s forwards;
}

@keyframes fadeAway {
  from {opacity: 1;}
  to {opacity: 0; z-index: -1;}
}

.white-gradient {
  position: fixed;
  width: 100vw;
  height: 55px;
  bottom: 0;
  left: 0;
  background: linear-gradient(transparent, white);
  z-index: 999;
}

.aside {
  display: none;
}

.main-content {
  padding: 20px 10px;
}

.business-form {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
}

.header {
  height: 55px;
  box-shadow: 0 0 8px rgba(99,102,241,.2);
}

.logo {
  display: flex;
  float: left;
  position: relative;
  top: 5px;
  left: 7px;
  max-width: 150px;
  padding: 0 20px;
  color: black;
  font-size: 13px;
  font-weight: bold;
}.logo-sub {
  display: inline;
  position: relative;
  top: -5px;
  left: 6px;
  padding: 2px 5px 0px 4px;
  border-radius: 5px;
  border: 3px solid;
  text-transform: uppercase;
}

.callus {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.btn-help {
  position: fixed;
  bottom: 14px;
  right: 14px;
  z-index: 9999;
}

input.form-control,
textarea.form-control {
  color: #585c7b;
  background-color: #fff;
  border-color: rgba(99,102,241,.35);
  outline: 0;
  box-shadow: inset 0 0 0 transparent, 0 0.5rem 1.125rem -0.5rem rgba(99,102,241,.2);
}

input.form-control:focus,
textarea.form-control:focus {
  border-color: #181212 !important;
  box-shadow: none;
}

.list-group-numbered {
  position: relative;
  top: -48px;
  left: -10px;
  padding: 20px 25px;
  border-radius: 0;
  border-left: 1px solid;
}

.text-danger {
  max-width: 450px;
}

.input-group-text {
  background-color: black;
  color: white;
  font-weight: bold;
  font-size: 12px;
  text-transform: capitalize;
  border-color: transparent;
}

.list-group-numbered .list-group-item {
  padding: 0 0 10px 0;
}
.list-group-numbered .list-group-item:last-child {
  padding: 0;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  background-color: transparent;
  cursor: default;
  color: inherit;
}

.card-related {
  font-size: 13px !important;
  font-weight: 500;
  letter-spacing: -0.3px;
  border: none;
  background-color: transparent;
}.card-related:hover {
  background-color: transparent !important;
}

.textarea-related {
  position: relative;
  top: 20px;
  min-height: 280px !important;
}

.serve-parties {
  max-width: 200px;
}

.form-control {
  position: relative;
}.form-control:hover {
  background-color: white;
}

.uploading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #6365f1;
  border-color: #6365f1;
  color: white;
  line-height: 45px;
  font-weight: bold;
  text-align: center;
}

.form-control.uploaded {
  position: relative;
  border-color: #22c55e;
  padding-right: calc(1.6em + 1.25rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2322c55e'%3e%3cpath d='M10 1.667c-4.595 0-8.333 3.738-8.333 8.333S5.405 18.333 10 18.333s8.333-3.738 8.333-8.333S14.595 1.667 10 1.667zm0 15c-3.676 0-6.667-2.991-6.667-6.667S6.324 3.333 10 3.333 16.667 6.324 16.667 10 13.676 16.667 10 16.667zm-1.668-5.345L6.416 9.41 5.24 10.59l3.094 3.088 5.588-5.588-1.178-1.178-4.412 4.412z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.3125rem) center;
  background-size: calc(0.8em + 0.625rem) calc(0.8em + 0.625rem);
}

.form-control::file-selector-button {
  background-color:#6365f1 !important;
  color: white;
  font-weight: bold;
}

.form-control.uploaded::file-selector-button {
  background-color:#22c55e !important;
}

.form-control.declined {
  border-color: #f04444;
}
.form-control.declined::file-selector-button {
  background-color: #f04444 !important;
}

.form-control.processing,
.form-control.approved,
.form-control.processing::file-selector-button,
.form-control.approved::file-selector-button {
  pointer-events: none;
}

.form-control.uploaded::before,
.form-control.processing::before,
.form-control.approved::before {
  display: block;
  content: 'Uploaded';
  position: absolute;
  top: 0;
  left: 0;
  width: 112px;
  background-color:#22c55e;
  color: white;
  font-weight: bold;
  line-height: 43px;
  text-align: center;
}
.form-control.processing::before {
  content: 'Processing';
  width: 100%;
  height: 100%;
  background-color: #fff;
  color: #22c55e;
}
.form-control.processing,
.form-control.approved {
  border-color: #22c55e;
}
.form-control.approved::before {
  content: 'Approved';
  width: 100%;
  height: 100%;
  background-color: #22c55e;
  color: #fff;
}

.pdf-example {
  border: 2px solid;
  border-radius: 3px;
  box-shadow: -10px 10px 0 0 rgb(214,214,214,.7);
}

.list-group-flush .list-group-item.previous {
  color: #22c55e;
  background-color: white;
  box-shadow: none;
}
.list-group-flush .list-group-item.current {
  position: relative;
  color: #6365f1;
  background-color: white;
  box-shadow: none;
  font-weight: bold;
}.list-group-flush .list-group-item.current::after {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #fff;
  border: 3px solid #6365f1;
  position: absolute;
  right: -23px;
  top: 15px;
  transform: rotate(45deg);
}

input#companyFoundedYear {
  max-width: 160px !important;
}

li.list-group-item.list-group-item-action.d-flex.align-items-center.active {
  position: relative;
  background-color: white !important;
  color: #6365f1 !important;
  box-shadow: none;
  font-weight: bold;
}
li.list-group-item.list-group-item-action.d-flex.align-items-center.active::before {
  content: '';
  position: absolute;
  top: 9px;
  width: 15px;
  left: -25px;
  height: 30px;
  background-color: #6365f1;
  border-radius: 0 5px 5px 0;
}

.agreement {
  width: calc(100% + 48px);
  height: 80vh;
  margin-left: -24px;
}

.animated-icon {
  max-width: 200px;
  margin: 30px 0 !important;
}

.stage-description {
  max-width: 500px;
}

.footer {
  position: relative;
  z-index: 99999;
}

input[type="month"]::-webkit-calendar-picker-indicator {
  position: absolute;
  font-size: 22px;
  width: 150px;
  right: 0;
  opacity: 0;
}

.case-closed {
  width: 100%;
  margin: 40vh auto;
  text-align: center;
}

.step.declined {
  padding: 30px 20px !important;
  border-radius: 15px;
  background: white;
  box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 4px #ff8188;
}
.step.declined::before,
.step.declined::after {
  z-index: -1;
}

.tracking-number {
  max-width: 230px;
}

@media all and (min-width: 768px) {
  .aside {
    display: block;
    min-height: 100vh;
  }
  .main-content {
    padding: 40px;
  }
}
