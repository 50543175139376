html, body, div, p, h1, h2, h3 {
  color: black;
}

.signature-page .text-gray {
  color: #ccc;
}

.signature-page .page {
  margin: 10px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.signature-page .page-container {
  max-width: 750px;
  margin: auto;
}

.signature-page .signature-header {
  position: fixed;
  top: 10px;
  width: calc(100% - 20px);
  margin-bottom: 40px;
  border-radius: 5px;
  text-align: center;
  z-index: 1;
}

.signature-page .signature-logo {
  width: 265px;
  padding: 10px;
  border-radius: 5px;
  background-color:#002a51;
}

.signature-page .signature-container {
  margin: 100px 0;
}

@media all and (min-width: 768px) {
  .signature-page .signature-container {
    max-width: 80vw;
  }
}

.signature-page .signature-block {
  display: block;
  position: relative;
}

.signature-page .vh-75 {
  height: 75vh;
}

.signature-page h1.contract-header {
  font-size: 18px;
}

.signature-page h2.contract-header {
  font-size: 16px;
}

.signature-page h3.contract-header {
  font-size: 14px;
  margin: 40px 0 0;
}

.signature-page h4.contract-header {
  font-size: 14px;
  margin: 0 0 10px;
  font-weight: normal;
}

.signature-page h5.contract-header {
  margin-top: 30px;
  font-size: 16px;
}

.signature-page .line-break {
  color: black;
  height: 2px !important;
}

.signature-page .sigCanvas {
  max-width: 100%;
  border-radius: 5px;
  background: #eee;
}

.signature-page .signature-line {
  display: block;
  position: absolute;
  top: 120px;
  left: 10%;
  max-width: 240px;
  width: 80%;
  height: 2px;
  border-radius: 5px;
  background-color: #666;
}

.signature-page .signature-line:after {
  content: "X";
  position: absolute;
  top: -35px;
  left: 5px;
  font-size: 22px;
}

.signature-page .field-container {
  margin: 5px;
}

.signature-page .field {
  margin: 0 5px;
  background-color: #eee;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  width: auto;
}

.signature-page .sign-button {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  background-color: orange;
  border-radius: 35px;
  color: black;
  font-size: 18px;
  font-weight: bold;
  text-indent: 10px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

@media all and (min-width: 768px) {
  .signature-page .signature-line {
    max-width: 400px;
  }

  .signature-page .sign-button {
    position: fixed;
    width: initial;
    margin-top: 220px;
    margin-left: -131px;
    border-radius: 35px 0 0 35px;
    text-indent: 10px;
    z-index: 1000;
  }
}

.signature-page .highlight {
  animation: flashHighlight 1s linear 2;
  animation-delay: 1s;
}

@keyframes flashHighlight {
  0% {
    background-color: initial;
  }
  50% {
    background-color: orange;
  }
  100% {
    background-color: initial;
  }
}

.signature-page .upload-form {
  max-width: 550px;
}

.signature-page .step {
  display: block !important;
  flex-basis: 0 !important;
  flex-grow: 1 !important;
  padding: 0 1.5rem !important;
}